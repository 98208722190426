
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

$body-family: 'Jost', sans-serif;
$heading-color: #192436;
$white-color: white;
$white-smoke-color: whitesmoke;
$copyright-color: #2372bb;
$box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
// $box-shadow: 0 0 20px 3px rgba(190, 37, 37, 0.05);
$transition: all ease .5s;
$blue: rgb(35, 101, 187);
$light-blue: rgb(35, 114, 187);

.text-muted {
	color: #6c757d !important;
}

.carousell p {
	font-size: 12px;
}

.justify {
	text-align: justify;
}

/*
About Area Style
======================================================
*/
.about-area {
	padding-top: 50px;
	margin-bottom: 50px;
}

/*
Advantages Area Style
======================================================
*/

// .advantages-area {
	// border-top: 1px solid $blue;
	// padding-top: 50px;
	// margin-bottom: 50px;
// }

/*
Fun Facts Area Style
======================================================
*/
.fun-fact-area {
	padding-top: 70px;
	padding-bottom: 50px;
	margin-top: 50px;
}
.single-fact {
	position: relative;
	padding-left: 110px;
	margin-bottom: 30px;
	i {
		position: absolute;
		left: 0;
		top: 5px;
		font-size: 70px;
		line-height: 1;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: -20px;
			width: 1px;
			height: 100%;
			background-color: #b5b9bf;
		}
	}
	h2 {
		font-family: $body-family;
		font-weight: 600;
		font-size: 40px;
		margin-bottom: 0;
	}
	p {
		// position: absolute;
		// right: -60px;
		font-weight: 600;
		text-transform: capitalize;
		font-size: 16px;
	}
}

/*
Pricing Area Style
======================================================
*/
.pricing {
	// border-top: 1px solid $blue;
	span {
		font-size: 19px;
	}
}
  
  .border-top { border-top: 1px solid #e5e5e5; }
  .border-bottom { border-bottom: 1px solid #e5e5e5; }
  
  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }  

/*
Main Banner Area Style
======================================================
*/
.main-banner {
	padding-top: 70px;
	border-top: 1px solid $blue;
}

/*
Footer Area Style
======================================================
*/
.footer-area {
	text-align: center;
	justify-content: center;
}

.footer-copy-right {
	text-align: center;
	p {
		a {
			color: $copyright-color;
		}
	}
}

/*
Services Area Style
======================================================
*/

.single-services-box {
	box-shadow: $box-shadow;
	padding: 30px;
	text-align: center;
	margin-bottom: 30px;
	transition: $transition;
	position: relative;
	min-height: 20vh;
	i {
		font-size: 50px;
		line-height: 1;
		margin-bottom: 20px;
		display: block;
	}
	&:hover {
		transform: translateY(-5px);
	}
}